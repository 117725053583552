import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  divider: {
    color: '#6D89AA',
    fontSize: '15px',
    fontWeight: 'bold',
    lineHeight: '18px',
    marginBottom: '8px',
  },
  labelAdornment: {
    color: '#0B6BDD',
  },
  labelContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '8px',
  },
  labelText: {
    color: '#575757',
    display: 'inline',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    marginRight: '4px',
  },
}));
