import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
} from '@material-ui/core';

import { Logo } from 'components';
import { useBreakpoint } from 'hooks';
import Account from 'layouts/CommonLayout/TopBar/Account';

function TopBar() {
  const breakpoint = useBreakpoint();
  const isFullbar = ['xs', 'sm'].includes(breakpoint);

  return (<>

    {isFullbar && (<AppBar>
      <Toolbar>
        <RouterLink to="/">
          <Logo maxHeight={48} type="dark" />
        </RouterLink>
        <Box ml={2} flexGrow={1} />
        <Box children={<Account />} ml={2} />
      </Toolbar>
    </AppBar>)}

    {!isFullbar && (<Box
      children={<Account />}
      mb={2}
      mt={2}
      textAlign="right"
    />)}

    <Box mb={5} mt={isFullbar ? 10 : 3}>
    </Box>
  </>);
}

TopBar.defaultProps = {
  value: 0,
};

TopBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TopBar;
