export const HEADERS = [
  {
    order: 1,
    pathname: [
      '/request/accessories/identity',
      '/request/iphone/identity',
      '/request/identity',
    ],
    hash: {
      '': {
        subheader: 'Puedes continuar tu proceso en tu dispositivo telefónico o continuar en tu computadora',
        title: 'Validación de identidad',
      },
      external: {
        addPercentage: 16,
        subheader: 'Escanea este código con la camara de tu dispositivo para iniciar con el proceso de verificación',
        title: 'Continúa el proceso en tu teléfono',
      },
      internal: {
        addPercentage: 16,
        subheader: 'A continuación se abrirá automáticamente una ventana emergente para continuar con tu validación',
        title: 'Continúa el proceso en tu computadora',
      },
    },
    step: 'Registro',
  },
  {
    hash: {
      step1: {
        addPercentage: 16,
        subheader: 'Calificación aprobatoria MINIMA para el crédito es de 25 Puntos',
        title: 'Evaluación de equipo',
        step: 1,
      },
      step2: {
        addPercentage: 16,
        subheader: 'Calificación aprobatoria MINIMA para el crédito es de 25 Puntos',
        title: 'Evaluación de equipo',
        step: 2,
      },
      step3: {
        addPercentage: 16,
        subheader: 'Calificación aprobatoria MINIMA para el crédito es de 25 Puntos',
        title: 'Evaluación de equipo',
        step: 3,
      },
    },
    isVisible: false,
    order: 1,
    pathname: '/request/questions',
    step: 'Registro',
  },
  {
    hash: {
      accepted: {
        align: 'center',
        title: '¡Felicidades, tu crédito ha sido aprobado! 🎊',
      },
      rejected: {
        align: 'center',
        title: '¡Lo sentimos, tu crédito ha sido rechazado! 😥',
      },
    },
    isVisible: false,
    order: 1,
    pathname: '/request/status',
    step: 'Registro',
  },
  {
    hash: {
      bad: {
        align: 'center',
        subheader: 'Recuerda que puedes volver a solicitar un crédito posterior a 30 días de este momento, te estaremos esperando',
        title: '¡Lo sentimos, tu score crediticio no cumple!',
      },
      good: {
        align: 'center',
        subheader: 'Procederemos a evaluar tu equipo, el cual debe ser Samsung con Android 11 o superior',
        title: '¡Felicidades, tu score créditicio es bueno! 🥳',
      },
    },
    isVisible: false,
    order: 1,
    pathname: '/request/score',
    step: 'Score',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: 'Ingresa los productos que deseas adquirir',
        title: 'Productos',
      },
    },
    isVisible: false,
    pathname: '/request/products',
    step: 'Productos',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: 'Ingresa los datos del teléfono donde se colocará el candado',
        title: 'Ahora compártenos la siguiente información',
      },
    },
    isVisible: false,
    pathname: '/request/device',
    step: 'Dispositivo',
  },
  {
    hash: {
      '': {
        subheader: '',
        title: 'Datos del credito',
      },
    },
    isVisible: false,
    pathname: '/request/loan_iphone',
    step: 'Crédito',
  },
  {
    hash: {
      '': {
        subheader: 'Tu crédito fue aprobado',
        title: 'Datos del crédito',
      },
    },
    order: 2,
    pathname: '/request/loan',
    step: 'Crédito',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: '',
        title: 'Ahora procederemos a instalar el candado en el teléfono del cliente',
      },
      otp: {
        align: 'center',
        subheader: 'Revisa tu teléfono e ingresa a la app para que te aparezca el siguiente OTP y lo coloques en la siguiente pantalla',
        title: 'Te hemos enviado un código',
      },
    },
    order: 3,
    pathname: '/request/software',
    step: 'Software',
  },
  {
    hash: {
      '': {
        subheader: 'Con fecha no mayor a tres meses, puede ser de CFE, agua o teléfono',
        title: 'Necesitamos tu comprobante de domicilio',
      },
    },
    isVisible: false,
    order: 4,
    pathname: '/request/documents',
    step: 'Documentos',
  },
  {
    hash: {
      '': {
        subheader: 'Necesitamos tu aprobación para finalizar el registro',
        title: 'Por último, acepta el contrato',
      },
    },
    order: 4,
    pathname: '/request/contract',
    step: 'Contrato',
  },
  {
    hash: {
      '': {
        subheader: 'Ahora puedes disfrutar de los beneficios que tenemos para ti.',
        title: '¡Proceso concluido con éxito!',
      },
    },
    pathname: '/request/finished',
    order: 5,
    step: 'Verificación',
  },
  {
    hash: {
      '': {
        subheader: '',
        title: 'Referencias personales',
      },
    },
    isVisible: false,
    pathname: '/request/relationships',
    order: 5,
    step: 'Referencias',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: 'Haz concluido el proceso de la instalación del candado, ahora vamos a probar el candado.',
        title: 'Validaremos el candado',
      },
    },
    isVisible: false,
    pathname: '/request/verify',
    order: 5,
    step: 'Validación',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: 'Verifica y completa los datos faltantes',
        title: 'Datos del cliente',
      },
    },
    isVisible: false,
    pathname: '/request/info',
    step: 'Verificación de datos',
  },
];
