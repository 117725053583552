import PropTypes from 'prop-types';

export const DEFAULT_PROPS = {
  value: [],
  onChange: () => { },
  settings: {},
};

export const PROP_TYPES = {
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    awsMetadata: PropTypes.object,
    hasMetadata: PropTypes.bool,
    readOnly: PropTypes.bool,
    route: PropTypes.string,
  }),
};
