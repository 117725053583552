import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  buttonCancel: {
    '&:hover': {
      backgroundColor: '#FA0000',
    },
    backgroundColor: '#FF0000',
    borderRadius: '5px',
    color: '#FFFFFF',
    fontSize: '14px',
    height: '50px',
    lineHeight: '16px',
  },
  buttonPrimary: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}cc`,
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '16px',
  },
  buttonSecondary: {
    '&:hover': {
      backgroundColor: '#DBDBDB',
    },
    backgroundColor: '#EEEEEE',
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '50px',
    lineHeight: '16px',
  },
}));
