import { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { Check as CheckIcon } from '@material-ui/icons';

import { Link, Logo } from 'components';
import { dynamicSort } from 'utils/arrays';

import { HEADERS } from '../constants';
import useStyles from './style';

function NavBar({ step }) {
  const classes = useStyles();
  const [headers, setHeaders] = useState();

  useEffect(() => {
    const data = HEADERS
      .sort(dynamicSort('order'))
      .filter(header => header.isVisible !== false);

    setHeaders(data);
  }, []);

  if (!headers) {
    return null;
  }

  return (<Box className={classes.container}>

    <Link to='/'>
      <Logo type="dark" />
    </Link>

    <Box>
      <Timeline align="left">
        {headers.map((header, index) => (<TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              children={step <= header.order
                ? (<Typography
                  children={index + 1}
                  className={classes.stepNumber}
                />)
                : <CheckIcon className={classes.check} />}
              className={clsx(
                step < header.order && classes.dotPending,
                step === header.order && classes.dotActive,
                step > header.order && classes.dotFinished,
              )}
              variant="default"
            />
            {index < headers.length - 1 && (<TimelineConnector className={classes.connector} />)}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              className={clsx(
                classes.stepTitle,
                header.order > step && classes.stepTextInactive,
              )}
              children={`Paso ${index + 1}`}
              component="h1"
              variant="h6"
            />
            <Typography
              className={clsx(
                classes.stepDescription,
                header.order > step && classes.stepTextInactive,
              )}
              children={header.step}
            />
          </TimelineContent>
        </TimelineItem>))}
      </Timeline>
    </Box>

  </Box>);
}

NavBar.propTypes = {
  step: PropTypes.number.isRequired,
};

export default NavBar;
