import {
  Fragment,
  lazy,
  Suspense,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';

import authenticationRoutes from './authentication';
import homeRoutes from './home';
import paymentsRoutes from './payments';
import publicRoutes from './public';
import requestRoutes from './request';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const redirect = () => <Redirect to="/home" />;

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, index) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes
                      ? (renderRoutes(route.routes))
                      : (<Component {...props} />)}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense >
  ) : null;

function Routes() {
  return renderRoutes([
    {
      component: redirect,
      exact: true,
      path: '/',
    },
    authenticationRoutes,
    homeRoutes,
    paymentsRoutes,
    publicRoutes,
    requestRoutes,
    error404,
  ]);
}

export default Routes;
