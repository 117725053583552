import { parseIfValid } from './json';

const SEPARATOR = '.';


function clone(value) {
  if (typeof structuredClone === 'undefined') {
    return {
      ...value,
    };
  } else {
    return structuredClone(value);
  }
};

/**
 * Picks a value from the object
 *
 * @param {object} obj
 * @param {string} key
 * @param {string} separator
 * @returns {any}
 */
function pickValue(obj, key, separator = SEPARATOR, parseChilds = false) {
  let value = obj;

  for (const k of (key || '').split(separator)) {
    if (!value) break;

    value = value[k] === undefined
      ? null
      : value[k];

    if (parseChilds && typeof value === 'string') {
      value = parseIfValid(value, true);
    }
  }

  return value;
}

/**
 * Update value on object with key by dot notation
 *
 * @param {object} obj
 * @param {string} key
 * @param {any} value
 * @param {string} separator
 * @returns {object}
 */
function setPickedKeyValue(obj, key, value, separator = SEPARATOR) {
  if (typeof key === 'string') {
    return setPickedKeyValue(obj, key.split(separator), value);
  } else if (key.length === 1 && value !== undefined) {
    return (obj[key[0]] = value);
  } else if (key.length === 0) {
    return obj;
  } else {
    if (typeof obj[key[0]] === 'undefined') {
      const isArray = parseInt(key[0]) || false;
      obj[key[0]] = isArray ? [] : {};
    }

    return setPickedKeyValue(obj[key[0]], key.slice(1), value);
  }
}

export {
  clone,
  pickValue,
  setPickedKeyValue,
};
