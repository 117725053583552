import { useEffect, useState } from 'react';
import { node } from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Hidden,
  Typography,
} from '@material-ui/core';

import { getSettings } from 'utils/settings';

import { HEADERS } from './constants';
import NavBar from './NavBar';
import useStyles from './styles';
import TopBar from './TopBar';

function RequestLayout({ children }) {
  const { location } = window;
  const [pageInfo, setPageInfo] = useState();
  const classes = useStyles();
  const site = getSettings('site', {});

  const updatePageInfo = function () {
    const strPathname = location.pathname
      .split('/')
      .filter((str) => !parseInt(str))
      .join('/');
    const cuerrentHeader = HEADERS
      .find((header) => Array.isArray(header.pathname)
        ? header.pathname.includes(strPathname)
        : header.pathname === strPathname);

    document.title = `${cuerrentHeader?.step} | ${site?.title || 'Blokfon'}`;
    if (site?.favicon) {
      document.querySelector('link[rel="icon"]').href = site.favicon;
    }

    const hash = (cuerrentHeader?.hash ?? {})[(location.hash || '').replace('#', '')];

    setPageInfo({
      header: {
        align: hash?.align,
        title: hash?.title,
        subheader: hash?.subheader,
        step: hash?.step,
      },
      step: {
        number: cuerrentHeader?.order,
        percentage: cuerrentHeader?.percentage + (hash?.addPercentage || 0),
      },
      maxWidth: cuerrentHeader?.maxWidth || 'md',
    });
  };

  useEffect(() => {
    updatePageInfo();
  }, [
    window.location.hash,
    window.location.pathname,
  ]);

  return (<Box className={classes.root}>

    <Hidden
      children={<NavBar step={pageInfo?.step?.number} />}
      smDown
    />

    <Box className={classes.wrapper}>
      <Box className={classes.contentContainer}>

        <Container maxWidth={pageInfo?.maxWidth}>

          <TopBar value={pageInfo?.step?.percentage} />

          <Card className={classes.content}>
            <CardHeader
              subheader={
                <div style={
                  pageInfo?.header?.step
                    ? {
                      display: 'flex',
                      justifyContent: 'space-between',
                    }
                    : {}
                }>
                  <Typography
                    align={pageInfo?.header?.align}
                    children={pageInfo?.header?.subheader}
                    className={classes.subheader}
                    display="block"
                  />
                  {pageInfo?.header?.step && (
                    <Typography
                      align={pageInfo?.header?.align}
                      children={`${pageInfo?.header?.step}/3`}
                      className={classes.subheader}
                    />
                  )}
                </div>}
              title={<Typography
                align={pageInfo?.header?.align}
                children={pageInfo?.header?.title}
                className={classes.title}
              />}
            />
            <CardContent children={children} />
          </Card>
        </Container>

      </Box>
    </Box>

  </Box>);
}

RequestLayout.propTypes = {
  children: node,
};

export default RequestLayout;
