import { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Modal } from 'components';

import useStyles from './styles';

function Video({ id, facingMode }) {
  const classes = useStyles();
  const [modal, setModal] = useState({});

  const showError = function (message) {
    setModal({
      data: {
        message,
      },
      open: true,
    });
  };

  useEffect(() => {
    const video = document.querySelector(`#${id}_video_stream`);

    if (typeof navigator?.mediaDevices?.enumerateDevices !== 'function') {
      showError('No se puede acceder a los dispositivos de medios');
      return;
    }

    navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {
        const videoinput = devices.filter((device) => device.kind === 'videoinput');

        if (videoinput.length === 0) {
          showError('No se encontraron dispositivos de captura de video');
          return;
        }

        const constraints = {
          audio: false,
          video: {
            facingMode: {
              ideal: facingMode,
            },
            height: 1080,
            width: 1920,
          },
        };

        navigator.mediaDevices.getUserMedia(constraints)
          .then(function (stream) {
            video.srcObject = stream;
          })
          .catch(function (error) {
            showError(process.env.NODE_ENV === 'development'
              ? error?.message
              : 'No se pudo acceder a las fuentes de video');
          });
      });

    return () => {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        track.stop();
      }

      video.srcObject = null;
    };
  }, []);

  return (<>

    <Modal
      open={modal?.open}
      title="Validación de identidad"
    >
      <Alert severity={modal?.data?.severity ?? 'error'}>
        <AlertTitle children="Error en dispositivos de video" />
        <Typography
          children={modal?.data?.message}
          variant="body2"
        />
      </Alert>
    </Modal>

    <video
      alt="Webcam video stream"
      autoPlay
      className={classes.video}
      id={`${id}_video_stream`}
      playsInline
    />
  </>);
}

Video.defaultProps = {
  facingMode: 'environment',
};

Video.propTypes = {
  facingMode: ['environment', 'user'],
  id: string.isRequired,
};

export default Video;
