import axios from 'axios';
import { stringify } from 'querystring';

import { API_URL } from 'settings';
import { getCurrentSessionData } from 'utils/aws/cognito';

const http = axios.create({
  baseURL: API_URL,
});

http.interceptors.request.use((config) => {
  const { accessToken } = getCurrentSessionData();

  if (accessToken) {
    config.headers.Authorization = accessToken;
  }

  return config;
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {

      const {
        hash = '',
        pathname,
        search = '',
      } = window.location;
      const query = {};

      if (!pathname.startsWith('/auth/')) {
        query.redirect = Buffer
          .from(`${pathname}${search}${hash}`)
          .toString('base64');
        window.location = `/auth/login?${stringify(query)}`;
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export default http;
