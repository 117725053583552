import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  backgroundCitycell: {
    background: '#E9EBC5',
  },
  bgFullHeight: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
    },
    width: '100%',
  },
  boxContentV2: {
    gap: '20px',
    padding: '20px',
  },
  card: {
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      width: '50%',
    },
    backgroundColor: 'transparent !important',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'visible',
    position: 'relative',
  },
  cardsContainer: {
    justifyContent: 'space-between !important',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '35px',
  },
  cardStepsV2: {
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      width: '50%',
    },
    backgroundColor: '#F3F6FC',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'visible',
    position: 'relative',
    width: '100% !important',
  },
  containerButtons: {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    float: 'right !important',
    marginTop: '10px',
  },
  content_1V2: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: '35px',
    },
    alignItems: 'center',
    background: 'linear-gradient(to bottom right, #FF488D, #3F97FF)',
    verticalAlign: 'middle',
  },
  content_1V3: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      height: 'auto',
      marginBottom: '35px',
    },
    alignItems: 'center',
    background: 'white',
    verticalAlign: 'middle',
    padding: '0',
  },
  content_2: {
    [theme.breakpoints.down('sm')]: {
      height: '370px',
      marginTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      width: '70%',
    },
    '& > div': {
      width: '100%',
    },
    width: '100%',
  },
  content_2V2: {
    [theme.breakpoints.down('sm')]: {
      height: '370px',
      marginTop: 0,
    },
    '& > div': {
      width: '100%',
    },
    width: '100%',
  },
  contentFormv2: {
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      maxHeight: '600px',
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      maxHeight: '600px',
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px',
      width: '100%',

    },
    backgroundColor: '#F7F7E8',
    borderRadius: '33.02px',
    boxShadow: '0px 20px 43px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px 40px',
    overflowY: 'auto',
    position: 'relative',
  },
  contentStepsFormVerificationV2: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
    },
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between',
    minHeight: '500px',
    width: '80%',
  },
  contentStepsVerificationV2: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      height: 'auto',
      justifyContent: 'center',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
    '&:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'end',
    },
    alignItems: 'center',
    display: 'flex !important',
    height: 'auto',
    justifyContent: 'center',
    width: '100%',
  },
  contentv2: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
    '&:nth-child(2)': {
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
    },
    justifyContent: 'center',
  },
  contentV2: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
    '&:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'end',
    },
    height: '600px',
  },
  contFooterv2: {
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    padding: '0 2%',
    verticalAlign: 'middle',
  },
  contImgFooter: {
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '80%',
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '0',
      textAlign: 'center',
      marginTop: 10,
    },
  },
  gridContainer: {
    alignItems: 'center',
    backgroundImage: 'url(assets/images/img-default.webp)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100% !important',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  imgFooter: {
    width: '100px',
    height: 'auto',
  },
  layoutHeader: {
    height: '70px',
    paddingTop: '15px',
    verticalAlign: 'middle',
    width: '100%',
    backgroundColor: '#66B245',
  },
  logoInLoginDefault: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(75vh + 150px)',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    padding: '0',
    position: 'relative',
  },
  noPadding: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 70px)',
    },
    height: 'calc(100vh - 70px)',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    width: '100%',
  },
  root: {
    height: '50%',
    minHeight: '100%',
  },
  roundedGrid: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '49% !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
    background: '#FFFFFF',
    border: '2px solid #CEE1F8',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  selectedCard: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '49% !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
    background: '#CEE1F8',
    border: '2px solid #FFFFFF',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  validationSubtitle: {
    color: '##575757',
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: '5px',
  },
  validationTitle: {
    color: '#0C3A72',
    fontSize: '30px',
    fontStyle: 'bold',
    lineHeight: '35px',
    marginBottom: '5px',
  },
}));
