import { useEffect, useState } from 'react';
import {
  bool,
  func,
  shape,
  string,
} from 'prop-types';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {
  Camera as CameraIcon,
  Close as CloseIcon,
  FlipCameraIos as FlipCameraIosIcon,
  PhotoCamera as PhotoCameraIcon,
} from '@material-ui/icons';

import useStyles from './styles';
import Video from './Video';

function CameraControl(props) {
  const [isCapturing, setIsCapturing] = useState(!(props?.settings?.hasField ?? true));
  const [image, setImage] = useState();
  const classes = useStyles();

  const handleCapture = function () {
    const canvasElement = document.getElementById(`${props.name}_canvas`);
    const videoElement = document.getElementById(`${props.name}_video_stream`);
    const context = canvasElement.getContext('2d');

    canvasElement.height = videoElement.videoHeight || parseInt(videoElement.style.height);
    canvasElement.width = videoElement.videoWidth || parseInt(videoElement.style.width);
    context.drawImage(videoElement, 0, 0);

    // Base 64
    const [, dataURL] = canvasElement.toDataURL('image/jpeg', 1.0).split(',');
    setImage(dataURL);
  };

  useEffect(() => {
    const target = {
      name: props.name,
      value: image,
    };

    props.onChange({
      target,
    });

  }, [image]);

  return (<>

    {(props?.settings?.hasField ?? true) && (<TextField
      id="camera_input"
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton
            children={isCapturing
              ? <CloseIcon />
              : <PhotoCameraIcon />}
            color={isCapturing ? 'secondary' : 'primary'}
            disabled={props.disabled}
            onClick={function () {
              setIsCapturing(!isCapturing);
              setImage();
            }}
            size="small"
          />
        </InputAdornment>,
        readOnly: true,
      }}
      label={props.label}
      name={props.name}
      fullWidth
      size="small"
      variant="outlined"
    />)}

    {isCapturing && (<>

      <canvas
        alt="Canvas image"
        className={image ? classes.canvas : classes.canvas_hide}
        id={`${props.name}_canvas`}
      />

      {!image && (<Video
        facingMode={props?.settings?.facingMode}
        id={props.name}
      />)}

      <Grid container spacing={1}>
        <Grid item md={12} sm={12} xs={12}>
          <Button
            color="primary"
            children={image ? 'Reintentar' : 'Capturar'}
            fullWidth
            onClick={function () {
              if (image) {
                setImage();
              } else {
                handleCapture();
              }
            }}
            size="small"
            startIcon={image
              ? <FlipCameraIosIcon />
              : <CameraIcon />}
            variant="contained"
          />
        </Grid>
      </Grid>
    </>)}
  </>);
}

CameraControl.defaultProps = {
  onChange: () => { },
};

CameraControl.propTypes = {
  disabled: bool,
  label: string,
  name: string,
  onChange: func,
  settings: shape({
    facingMode: string,
    hasField: bool,
  }),
};

export default CameraControl;
