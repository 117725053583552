import PropTypes from 'prop-types';

const optionsProps = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.string,
    ]),
    name: PropTypes.string,
  })),
  PropTypes.func,
]);

export const SELECT_PROP_TYPES = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  settings: PropTypes.shape({
    autocomplete: PropTypes.bool,
    emptyElement: PropTypes.string,
    group: PropTypes.bool,
    multiple: PropTypes.bool,
    nullItem: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ]),
    options: optionsProps,
  }),
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export const SELECT_AUTOCOMPLETE_PROP_TYPES = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  events: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  options: optionsProps,
  helperText: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export const SELECT_MENU_PROP_TYPES = {
  disabled: PropTypes.bool,
  emptyElement: PropTypes.string,
  error: PropTypes.any,
  events: PropTypes.object,
  group: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: optionsProps,
  size: PropTypes.string,
  value: PropTypes.any,
};

export const SELECT_MULTIPLE_PROP_TYPES = {
  disabled: PropTypes.bool,
  error: PropTypes.any,
  events: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: optionsProps,
  size: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any),
};
