import { func, string } from 'prop-types';
import { TextField } from '@material-ui/core';

function TextControl(props) {
  const { onBlur, onChange } = props;

  return (
    <TextField
      fullWidth={true}
      label={props.label}
      name={props.name}
      size="small"
      variant="outlined"
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

TextControl.defaultProps = {
  onChange: () => { },
  onBlur: () => { },
};

TextControl.propTypes = {
  label: string,
  name: string,
  onChange: func,
  onBlur: func,
};

export default TextControl;
