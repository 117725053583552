export const HEADERS = [
  {
    hash: {
      '': {
        align: 'center',
        subheader: '',
        title: 'Realizar nuevo pago',
      },
    },
    order: 1,
    pathname: '/payments/find',
    step: 'Número de referencia',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: '',
        title: '',
      },
    },
    order: 2,
    pathname: '/payments/process',
    step: 'Plazo a pagar',
  },
  {
    hash: {
      '': {
        align: 'center',
        subheader: 'El monto pagado se verá reflejado en el historial del cliente',
        title: '¡Pago realizado!',
      },
    },
    isVisible: false,
    order: 3,
    pathname: '/payments/finished',
    step: '¡Pago realizado!',
  },
];
