import { alpha, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  card: {
    border: '1px solid transparent',
    color: '#212529',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c3d6fe',
      border: '1px solid transparent',
      borderColor: '#8CB0FD !important',
      borderRadius: '0.25rem',
      transition: [
        'color .15s ease-in-out',
        'background-color .15s ease-in-out',
        'border-color .15s ease-in-out',
        'box-shadow .15s ease-in-out',
      ].join(','),
    },
    '&:active': {
      backgroundColor: '#8CB0FD',
      borderColor: '#8cb0fd',
    },
  },
  cardSelected: {
    backgroundColor: '#8CB0FD',
    border: '1px solid transparent',
    borderRadius: '0.25rem',
    color: '#212529',
    cursor: 'pointer',
    transition: [
      'color .15s ease-in-out',
      'background-color .15s ease-in-out',
      'border-color .15s ease-in-out',
      'box-shadow .15s ease-in-out',
    ].join(','),
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.7),
    },
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
    },
  },
  secondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.7),
    },
  },
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.7),
    },
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.7),
    },
  },
}));
