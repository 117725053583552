import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import { createElement } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { enableES5 } from 'immer';
import * as Sentry from '@sentry/react';

import { configureStore } from 'redux/store';
import { AWS_AMPLIFY } from 'settings/aws';

import App from './App';
import './locales';

enableES5();

const store = configureStore();

Amplify.configure(AWS_AMPLIFY);

if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

Sentry.init({
  dsn: 'https://3910aa9db15898c70f98f93e1dc12d40@o4505370248740864.ingest.sentry.io/4506837646835712',
  initialScope: {
    tags: {
      'app.name': 'citycell/seller',
      'app.version': process.env.REACT_APP_BUILD_VERSION ?? '',
    },
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

render(
  createElement(Provider, {
    children: createElement(App),
    store,
  }),
  document.getElementById('root'),
);
