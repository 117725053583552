import { Link as RouterLink } from 'react-router-dom';
import { string } from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  makeStyles,
} from '@material-ui/core';

import { Logo } from 'components';
import { THEMES } from 'constants/index';
import Account from 'layouts/CommonLayout/TopBar/Account';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({ className }) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>

        <Hidden mdDown>
          <RouterLink to="/">
            <Logo maxHeight={48} type="dark" />
          </RouterLink>
        </Hidden>

        <Box ml={2} flexGrow={1} />

        <Box children={<Account />} ml={2} />

      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: string,
};

export default TopBar;
