import { stringify } from 'querystring';

import axios from 'utils/axios';
import { populate } from 'utils/strings';

import { processResponse } from './functions/response';

let resource;

/**
 * Send request by GET method
 *
 * @param {object} filters
 * @param {boolean} sort
 * @returns
 */
async function get(filters = {}, sort = false) {
  const qs = stringify(filters);
  const response = await axios.get(qs ? `${resource}?${qs}` : resource);

  return processResponse(response, filters, sort);
}

/**
 * Send request by POST method
 *
 * @param {object} payload
 * @returns
 */
async function post(payload, responseAsGet = false) {
  const response = await axios.post(resource, payload);
  const { data: { data = [], total = 0 } } = response;

  if (responseAsGet) {
    return {
      values: data,
      total,
    };
  } else {
    const [values] = Array.isArray(data) ? data : [data];

    return values;
  }
}

/**
 * Send request by PUT method
 *
 * @param {object} payload
 * @returns
 */
async function put(payload) {
  const response = await axios.put(resource, payload);
  const { data: { data = [] } } = response;
  const [values] = Array.isArray(data) ? data : [data];

  return values;
}

/**
 * Send request by DELETE method
 *
 * @param {object} filters
 * @param {boolean} inBody
 * @returns
 */
async function del(filters = {}, inBody = false) {
  const qs = stringify(filters);
  const response = inBody
    ? await axios.delete(resource, {
      data: filters,
    })
    : await axios.delete(qs ? `${resource}?${qs}` : resource);
  const { data: { data } } = response;
  const [values] = Array.isArray(data) ? data : [data];

  return values;
}

/**
 *
 * @param {string} service
 * @param {object} params
 * @returns
 */
export const Dynamicore = (service, params = {}) => {
  resource = populate(service, params);

  return {
    get,
    post,
    put,
    delete: del,
  };
};
