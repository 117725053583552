export const SERVICES = {
  ASSETS: '/services/assets',
  ASSETS_GROUPS: '/services/assets/groups',

  ACCOUNTS: '/private/accounts',
  ACCOUNTS_CALENDAR: '/private/accounts/payment_calendar',
  ACCOUNTS_CALENDAR_BY_CONFIG: '/private/accounts/payment_calendar_by_config',
  ACCOUNTS_DOCUMENTS: '/private/accounts/documents',
  ACCOUNTS_MOVEMENTS: '/private/accounts/movements',
  ACCOUNTS_PROCESSOR: '/private/accounts/processor',
  ACCOUNTS_TABLE: '/private/accounts/table',
  ACCOUNTS_TABLE_IDEAL: '/private/accounts/ideal_table',

  APPS_CONEKTA_CREATE_ORDER: '/marketplace/apps/conekta/order/v2/',
  APPS_CONEKTA_GET_ORDER: '/marketplace/apps/conekta/order/:account',
  APPS_CREDITS_PAYMENTS: '/marketplace/apps/credits/payments',
  APPS_CITYCELL_PAYMENTS: '/marketplace/apps/citycell/payments',
  APPS_CITYCELL_PHONE_EVALUATION: '/marketplace/apps/citycell/phone_evaluation',
  APPS_EEZLOAN_ACTIONS: '/marketplace/apps/eezloan/actions/:action',
  APPS_EEZLOAN_FLOW: '/marketplace/apps/eezloan/flows',
  APPS_EEZLOAN_DEVICE_APP_VERIFY: '/marketplace/apps/eezloan/device/app_verify',
  APPS_WEETRUST_VERIFY_FLOW: '/marketplace/apps/weetrust/identity/verify_flow',
  APPS_WEETRUST_GET_RESULTS: '/marketplace/apps/weetrust/biometric/getClientResults/:client',

  CATALOGS_ZIPCODE: '/private/codecatalog/code',

  COMPANY: '/private/company',

  CONNECTOR_FLOW: '/internal/connector/:company/flows/:flow',

  GROUPS: '/private/groups',

  PEOPLE: '/private/clients',
  PEOPLE_ORGANIZATIONAL: 'private/clients/organizational',
  PEOPLE_PII: '/private/clients/pii',
  PEOPLE_PII_FILTER: '/private/clients/pii_filter',
  PEOPLE_TYPES: '/private/clients/types',

  PRODUCTS: '/private/products',

  SEARCH_DOCUMENTS: '/private/search/documents',

  USERS_GET_INFO: '/private/users/get_info',
  USERS_PRESIGNIN_DOCUMENT: '/users/presignin_documents',
  USERS_SEND_CODE_MFA: '/users/sendCodeMFA',
  USERS_SIGNIN_DOCUMENT: '/users/signin_documents',
  USERS_UPDATE_ATTRIBUTES: '/users/updateUserAttributes',
  USERS_VERIFY_MFA_OTP: '/users/verifyMfaOtp',
  USERS_WEBCLIENTS: '/users/webclients',

  WS_PUBLISH: '/internal/wspublish',
};

export const CONF = {};
