import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('lg')]: {
      padding: '0 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0px',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      textAlign: 'center',
    },
    '& > span': {
      color: '#6D89AA',
      fontSize: '12px',
    },
    '& > span > a': {
      color: '#6D89AA',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0',
    textAlign: 'center',
    width: '100%',
  },
  footer: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '0',
      textAlign: 'center',
    },
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: 0,
    color: '#ffffff',
    display: 'flex',
    height: '50px',
    justifyContent: 'center',
    left: 'auto',
    marginTop: '20px',
    right: 0,
    width: '100%',
    zIndex: 1,
  },
  separator: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    margin: '0 10px',
  },
}));
