function getCircularReplacer() {
  const seen = new WeakSet();

  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

/**
 * Parse JSON string
 *
 * @param {string} str JSON input string
 * @param {boolean} returnOriginal Return original data if error
 * @returns {object|string|null} JSON object
 */
function parseIfValid(str, returnOriginal = true) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return returnOriginal ? str : null;
  }
}

export {
  parseIfValid,
  getCircularReplacer,
};
