/* eslint-disable no-template-curly-in-string */

const MESSAGES = {
  EMAIL: 'Escribe una dirección de correo electrónico válida',
  LENGTH: 'El valor debe ser de ${length} caracteres',
  MAX_LENGTH: 'El valor debe ser máximo ${max} caracteres',
  MIN_LENGTH: 'El valor debe ser mínimo ${min} caracteres',
  ONE_OF: 'Selecciona alguna opción',
  PASSWORD: 'La contraseña debe contener al menos 8 caracteres, mayúsculas y minúsculas',
  PHONE_NUMBER: 'Debe ser un número de teléfono válido',
  REQUIRED: 'Campo requerido',
  TYPE_ERROR: 'El valor no es válido',
  URL: 'Escribe una URL válida',
  USERNAME: 'Escriba un teléfono o correo electrónico válido',
};

export default MESSAGES;
