import { lazy } from 'react';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const basePath = '/public';

const publicRoutes = {
  path: basePath,
  routes: [
    {
      component: lazy(() => import('views/Public/DigitalAuthentication')),
      exact: true,
      path: `${basePath}/digital-authentication`,
    },
    {
      component: lazy(() => import('views/Public/Result')),
      exact: true,
      path: `${basePath}/result`,
    },
    error404,
  ],
};

export default publicRoutes;
