import { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  CssBaseline,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es';

import { Auth, ScrollReset } from 'components';
import Routes from 'routes';
import { createTheme } from 'theme';
import { initSettings } from 'utils/settings';

const history = createBrowserHistory();
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

function App() {
  const [hasSettings, setHasSettings] = useState(false);
  const [theme, setTheme] = useState({});
  useStyles();

  useEffect(() => {
    initSettings()
      .then(function (settings) {
        setTheme(settings?.theme);
        setHasSettings(!!settings);
      });
  }, []);

  if (!hasSettings) {
    return null;
  }

  return (<>
    <CssBaseline />
    <ThemeProvider theme={createTheme(theme)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth
                children={[
                  <ScrollReset key="scrollReset" />,
                  <Routes key="routes" />,
                ]}
              />
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  </>);
}

export default App;
