import { CONF } from '../constants';

/**
 *
 * @param {any} data
 * @param {object} filters
 * @param {boolean} sort
 * @returns
 */
export function processResponse(response = {}, filters, sort) {
  const { config, data = {} } = response;
  let { data: values, total } = data;
  const [resource] = (config?.url || '').split('?');

  if (values === null || values === undefined) {
    values = [];
  }

  if (typeof values === 'string') {
    try {
      values = JSON.parse(values);
    } catch (e) {
      values = [];
    }
  }

  const {
    getById = true,
    sort: defaultSort,
  } = CONF[resource] || {};

  if ('id' in filters && getById) {
    [values] = values;

    return values;
  }

  const sortCriteria = sort || defaultSort;
  if (sortCriteria) {
    values.sort((a, b) => a[sortCriteria] - b[sortCriteria]);
  }

  if (!total) {
    total = 0;
  }

  return {
    values,
    total,
  };
}
