import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  button: {
    padding: '2px',
    border: '#00000014',
    borderStyle: 'dashed',
    borderRadius: '5%',
    width: '120px',
  },
  imageList: {
    flexWrap: 'nowrap',
    margin: '10px !important',
  },
  verified: {
    color: '#ffffff',
    backgroundColor: '#008a00',
    borderRadius: '15px',
    marginRight: '10px',
  },
  title: {
    color: theme.palette.secondary.contrastText,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%, rgba(0,0,0,0) 100%)',
  },
}));
