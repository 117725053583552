import { Link as RouterLink } from 'react-router-dom';
import {
  func,
  node,
  oneOf,
  string,
} from 'prop-types';
import { Link as MuiLink } from '@material-ui/core';

function Link(props) {
  const componentProps = {};

  switch (props.variant) {
    default: {
      componentProps.component = RouterLink;
      componentProps.to = props.to;
      break;
    }

    case 'asset': {
      componentProps.href = `${props.to}`;
      componentProps.target = '_blank';
      break;
    }

    case 'email': {
      componentProps.href = `mailto: ${props.to}`;
      break;
    }

    case 'external': {
      componentProps.href = `//${props.to}`;
      componentProps.target = '_blank';
      break;
    }

    case 'function': {
      componentProps.onClick = props.onClick;
      break;
    }

    case 'telephone': {
      componentProps.href = `tel: ${props.to}`;
      break;
    }
  }

  return (<MuiLink
    color={props.color}
    children={props.children}
    style={{
      cursor: 'pointer',
    }}
    underline={props.underline}
    {...componentProps}
  />);
}

Link.defaultProps = {
  onClick: () => { },
  underline: 'none',
  variant: 'router',
};

Link.propTypes = {
  color: oneOf([
    'error',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
  ]),
  children: node,
  onClick: func,
  to: string,
  underline: oneOf([
    'always',
    'hover',
    'none',
  ]),
  variant: oneOf([
    'asset',
    'email',
    'external',
    'function',
    'router',
    'telephone',
  ]),
};

export default Link;
