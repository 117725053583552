import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  canvas: {
    borderColor: theme.palette.grey[500],
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'block',
    marginTop: 5,
    minHeight: 220,
    width: '100%',
  },
  canvas_hide: {
    display: 'none',
  },
  video: {
    borderColor: theme.palette.grey[500],
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    marginTop: 5,
    width: '100%',
  },
}));
