export const {
  REACT_APP_AWS_BUCKET: AWS_BUCKET,
  REACT_APP_AWS_REGION: AWS_REGION,
  REACT_APP_AWS_USER_POOLS_ID: AWS_USER_POOLS_ID,
  REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID: AWS_USER_POOLS_WEB_CLIENT_ID,
} = process.env;

export const AWS_AMPLIFY = {
  // aws_cognito_identity_pool_id: '',
  aws_cognito_region: AWS_REGION,
  aws_project_region: AWS_REGION,
  aws_user_pools_id: AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
  // oauth: {},
};

export const AWS_CREDENTIALS = {
  accessKeyId: '',
  secretAccessKey: '',
};
