import { Box } from '@material-ui/core';

import useStyles from './styles';

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box lg={12} className={classes.container}>
        <span>© 2023 CityCell, Mexico City</span>
        <span className={classes.separator}>|</span>
        <span>
          <a
            children="Términos y condiciones"
            href="/public/terms_and_conditions"
            rel="noreferrer"
          />
        </span>
        <span className={classes.separator}>|</span>
        <span>
          <a
            children="Aviso de privacidad"
            href="/public/privacy_notice"
            rel="noreferrer"
          />
        </span>
      </Box>
    </footer>
  );
}

export default Footer;
