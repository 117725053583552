import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: '15px',
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  default: {
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  primary: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    color: theme.palette.secondary.main,
  },
  error: {
    backgroundColor: alpha(theme.palette.error.main, 0.08),
    color: theme.palette.error.main,
  },
  success: {
    backgroundColor: alpha(theme.palette.success.main, 0.08),
    color: theme.palette.success.main,
  },
  warning: {
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
    color: theme.palette.warning.main,
  },
}));
