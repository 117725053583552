import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  content: {
    borderRadius: '10px',
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '50px 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 5px',
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  subheader: {
    color: '#575757',
    fontSize: '15px',
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  title: {
    color: '#0C3A72',
    fontSize: '30px',
    fontWeight: 'bold',
    lineHeight: '35px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
  },
  wrapper: {
    backgroundColor: '#F2F7FC',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'scroll',
  },
}));
