import _ from 'lodash';
import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import { esES as esLocale } from '@material-ui/core/locale';

import { THEMES } from 'constants/index';

import typography from './typography';
import { softShadows, strongShadows } from './shadows';

const baseConfig = {
  direction: 'ltr',
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'unset !important',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'unset !important',
      },
    },
  },
  typography,
};

export function createTheme(settings = {}) {
  const themeConfigs = [
    {
      name: THEMES.LIGHT,
      overrides: {
        MuiTimelineItem: {
          missingOppositeContent: {
            '&:before': {
              display: 'none',
            },
          },
        },
        MuiInputBase: {
          input: {
            '&::placeholder': {
              color: colors.blueGrey[600],
              opacity: 1,
            },
          },
        },
        MuiTableCell: {
          head: {
            '& .MuiButton': {
              color: `${colors.common.white} !important`,
            },
            backgroundColor: `${settings?.colors?.secondary || '#0C3A72'} !important`,
            color: `${colors.common.white} !important`,
          },
        },
      },
      palette: {
        action: {
          active: colors.blueGrey[600],
        },
        background: {
          default: colors.common.white,
          paper: colors.common.white,
        },
        primary: {
          main: settings?.colors?.primary || '#0B6BDD',
        },
        secondary: {
          main: settings?.colors?.secondary || '#0C3A72',
        },
        text: {
          primary: colors.blueGrey[900],
          secondary: colors.blueGrey[600],
        },
        type: 'light',
      },
      shadows: softShadows,
    },
    {
      name: THEMES.ONE_DARK,
      palette: {
        action: {
          active: 'rgba(255, 255, 255, 0.54)',
          disabled: 'rgba(255, 255, 255, 0.26)',
          disabledBackground: 'rgba(255, 255, 255, 0.12)',
          focus: 'rgba(255, 255, 255, 0.12)',
          hover: 'rgba(255, 255, 255, 0.04)',
          selected: 'rgba(255, 255, 255, 0.08)',
        },
        background: {
          dark: '#1c2025',
          default: '#282C34',
          paper: '#282C34',
        },
        primary: {
          main: '#8a85ff',
        },
        secondary: {
          main: '#8a85ff',
        },
        text: {
          primary: '#e6e5e8',
          secondary: '#adb0bb',
        },
        type: 'dark',
      },
      shadows: strongShadows,
    },
    {
      name: THEMES.UNICORN,
      palette: {
        action: {
          active: 'rgba(255, 255, 255, 0.54)',
          disabled: 'rgba(255, 255, 255, 0.26)',
          disabledBackground: 'rgba(255, 255, 255, 0.12)',
          focus: 'rgba(255, 255, 255, 0.12)',
          hover: 'rgba(255, 255, 255, 0.04)',
          selected: 'rgba(255, 255, 255, 0.08)',
        },
        background: {
          dark: '#222431',
          default: '#2a2d3d',
          paper: '#2a2d3d',
        },
        primary: {
          main: '#a67dff',
        },
        secondary: {
          main: '#a67dff',
        },
        text: {
          primary: '#f6f5f8',
          secondary: '#9699a4',
        },
        type: 'dark',
      },
      shadows: strongShadows,
    },
  ];

  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      {
        direction: settings.direction,
      },
    ),
    esLocale,
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
