import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Hidden,
} from '@material-ui/core';

import { useBreakpoint } from 'hooks';
import { dynamicSort } from 'utils/arrays';

import useStyles from './styles';

function StepActions({ cancel, primary, secondary }) {
  const classes = useStyles();
  const breakpoint = useBreakpoint();

  return (<Box mt={3}>
    <Grid container spacing={2}>

      <Grid item md={2} xs={12}>
        {(cancel && cancel?.hidden !== true) && (<Button
          className={classes.buttonCancel}
          children={cancel?.label || 'Cancelar'}
          disabled={cancel?.disabled}
          fullWidth
          onClick={cancel?.fn}
          variant="contained"
        />)}
      </Grid>

      <Hidden
        children={<Grid item md={4} />}
        smDown
      />

      {[
        {
          component: (<Grid item key="secondary_button" md={3} sm={6} xs={12}>
            {(secondary && secondary?.hidden !== true) && (<Button
              className={classes.buttonSecondary}
              children={secondary?.label || 'Regresar'}
              disabled={secondary?.disabled}
              fullWidth
              onClick={secondary?.fn}
              variant="contained"
            />)}
          </Grid>),
          order: 1,
        },
        {
          component: (<Grid item key="primary_button" md={3} sm={6} xs={12}>
            <Button
              className={classes.buttonPrimary}
              children={primary?.label || 'Continuar'}
              disabled={primary?.disabled}
              form={primary?.form}
              fullWidth
              onClick={primary?.fn}
              type={primary?.form ? 'submit' : 'button'}
              variant="contained"
            />
          </Grid>),
          order: ['xs'].includes(breakpoint) ? 0 : 2,
        },
      ]
        .sort(dynamicSort('order'))
        .map(item => item.component)}

    </Grid>
  </Box>);
}

const buttonProps = PropTypes.shape({
  disabled: PropTypes.bool,
  fn: PropTypes.func,
  form: PropTypes.string,
  hidden: PropTypes.bool,
  label: PropTypes.string,
});

StepActions.propTypes = {
  primary: buttonProps,
  secondary: buttonProps,
  cancel: buttonProps,
};

export default StepActions;
