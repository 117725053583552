import clsx from 'clsx';
import {
  number,
  oneOf,
  string,
} from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import defaultCompanyLogo from 'assets/images/logo-blockfon.svg';
import { getSettings } from 'utils/settings';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  container: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
  logo: {
    maxHeight: '5rem',
    maxWidth: '250px',
  },
}));
function Logo({ alt, maxHeight, replaceBy, type }) {
  const classes = useStyles();
  const companyLogo = getSettings(`logo.${type}`);

  return (
    <Box className={clsx(!maxHeight && classes.container, classes.center)}>
      <img
        alt={alt}
        className={classes.logo}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = defaultCompanyLogo;
        }}
        src={replaceBy || companyLogo || ''}
        style={{
          maxHeight: maxHeight,
        }}
      />
    </Box>
  );
}

Logo.defaultProps = {
  alt: 'Company logo',
  type: 'light',
};

Logo.propTypes = {
  alt: string,
  maxHeight: number,
  replaceBy: string,
  type: oneOf([
    'dark',
    'isotipo',
    'light',
  ]),
};

export default Logo;
