export const HEADERS = [
  {
    pathname: '/auth/login',
    title: 'Iniciar sesión',
  },
  {
    pathname: '/auth/recovery',
    title: 'Recuperar contraseña',
  },
];
