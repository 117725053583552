import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './styles';

function Label(props) {
  const classes = useStyles();
  const {
    children,
    className,
    color,
    style,
    variant,
    ...rest
  } = props;

  return (
    <span
      children={children}
      className={clsx(
        classes.root,
        (variant === 'uppercase' ? classes.uppercase : undefined),
        {
          [classes[color]]: color,
        },
        className,
      )}
      {...rest}
      style={style}
    />
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  style: PropTypes.object,
  variant: PropTypes.oneOf([
    'normal',
    'uppercase',
  ]),
};

Label.defaultProps = {
  className: '',
  color: 'secondary',
  variant: 'normal',
};

export default Label;
