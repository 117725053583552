import { jwtDecode } from 'jwt-decode';

import { AWS_USER_POOLS_WEB_CLIENT_ID } from 'settings/aws';
import { parseIfValid } from 'utils/json';

function getCurrentSessionData() {
  const payload = {};
  let storageKey = `CognitoIdentityServiceProvider.${AWS_USER_POOLS_WEB_CLIENT_ID}`;

  storageKey += `.${localStorage.getItem(`${storageKey}.LastAuthUser`)}`;

  [
    'accessToken',
    'idToken',
    'refreshToken',
    'userData',
  ].forEach(key => {
    payload[key] = parseIfValid(localStorage.getItem(`${storageKey}.${key}`));
  });

  if (payload?.userData?.UserAttributes) {
    payload.userData.UserAttributes = Object
      .fromEntries((payload.userData?.UserAttributes || [])
        .map(({ Name, Value }) => [
          Name,
          Value,
        ]));
  }

  try {
    payload.decodeAccessToken = jwtDecode(payload.accessToken);
  } catch (e) {
    payload.decodeAccessToken = null;
  }

  return payload;
}

export {
  getCurrentSessionData,
};
