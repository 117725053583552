import { useEffect } from 'react';
import { node } from 'prop-types';
import {
  Card,
  CardContent,
} from '@material-ui/core';

import defaultImage from 'assets/images/image-background-login.png';
import { Logo } from 'components';
import { getSettings } from 'utils/settings';

import { HEADERS } from './constants';
import Footer from './Footer';
import useStyles from './styles';
import powered from '../../assets/images/powered-img.png';

function AutheticationLayout({ children }) {
  const { location } = window;
  const classes = useStyles();
  const site = getSettings('site', {});
  const centralImage = getSettings('modules.auth.assets.image', '');

  const updatePageInfo = function () {
    const header = HEADERS.find(({ pathname }) => pathname === location.pathname);

    document.title = `${header?.title} | ${site?.title || 'Blokfon'}`;
    if (site?.favicon) {
      document.querySelector('link[rel="icon"]').href = site.favicon;
    }
  };

  useEffect(() => {
    updatePageInfo();
  }, [window.location.hash, window.location.pathname]);

  return (
    <div className={classes.root}>
      <header className={classes.layoutHeader}>
        <Logo maxHeight={48} type="light" />
      </header>

      <div className={classes.noPadding} maxWidth="lg">
        <Card className={classes.card}>
          <CardContent className={[classes.contentv2, classes.content_1V3]}>
            <img
              alt="Logo"
              className={classes.logoInLoginDefault}
              src={centralImage}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImage;
              }}
            />
          </CardContent>

          <CardContent className={[classes.contentv2, classes.backgroundCitycell]}>
            <CardContent
              children={children}
              className={[classes.content_2, classes.contentFormv2]}
            />
            <div
              children={<Footer />}
              className={classes.contFooterv2}
            />
            <div className={classes.contImgFooter}>
              <img
                alt=""
                className={classes.imgFooter}
                src={powered}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

AutheticationLayout.propTypes = {
  children: node,
};

export default AutheticationLayout;
