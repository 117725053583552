import { useLocation } from 'react-router-dom';
import { parse as queryParse } from 'querystring';

function useQuery() {
  const { search } = useLocation();
  const parsed = queryParse(search.substring(1));
  const entries = Object.keys(parsed).map((key) => {
    let val = parsed[key];

    if (parseFloat(val) && String(parseFloat(val)) === parsed[key]) {
      val = parseFloat(val);
    }

    if (val === 'true') {
      val = true;
    }

    if (val === 'false') {
      val = false;
    }

    return [key, val];
  });

  return Object.fromEntries(entries);
};

export default useQuery;
