import { oneOf } from 'prop-types';
import {
  CircularProgress,
  Grid,
} from '@material-ui/core';

function Loader({ color }) {
  return (<Grid container justifyContent="center">
    <CircularProgress
      color={color}
      size={30}
      style={{
        marginTop: 25,
      }}
    />
  </Grid>);
}

Loader.defaultProps = {
  color: 'secondary',
};

Loader.propTypes = {
  color: oneOf([
    'primary',
    'secondary',
  ]),
};

export default Loader;
