import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  MenuItem,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Divider } from 'components';
import {
  InputCurrency,
  InputMxPhone,
} from 'components/DynamicForm/controls/NumberControl/inputs';
import { pickValue, setPickedKeyValue } from 'utils/objects';

import useStyles from './styles';
import { generateCurp } from 'utils/people';

const TYPES = {
  DATEPICKER: 'datepicker',
  MASK: 'mask',
  SELECT: 'select',
  TEXT: 'text',
};

const BREAKPOINTS = {
  lg: 12,
  md: 12,
  sm: 12,
  xl: 12,
  xs: 12,
};

const StyledKeyboardDatePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
    },
  },
})(KeyboardDatePicker);

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6DA6EB',
        borderRadius: '8px',
        borderWidth: '2px',
      },
    },
  },
})(TextField);

const StyledTooltip = withStyles({
  arrow: {
    color: '#eeeeee',
  },
  tooltip: {
    backgroundColor: '#eeeeee',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
})(Tooltip);

function Form(props) {
  const {
    fields,
    handleSubmit,
    id,
    initialValues,
    title,
    validationSchema,
  } = props;
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});

  const onBlur = function ({ target }) {
    const obj = structuredClone(touched);

    setPickedKeyValue(obj, target.name, true);
    setTouched(obj);
  };

  const onChange = function ({ target }) {
    const objErrors = structuredClone(errors);
    setPickedKeyValue(objErrors, target.name, null);
    setErrors(objErrors);

    const objValues = structuredClone(values);
    setPickedKeyValue(objValues, target.name, target.value);

    const curpFields = [
      'pii.name',
      'pii.secondname',
      'pii.lastname',
      'pii.motherlastname',
      'pii.birthdate',
      'pii.birthstate',
      'pii.sex',
    ];
    if (curpFields.includes(target.name)) {
      const curp = generateCurp({
        name: [
          objValues?.pii?.name,
          objValues?.pii?.secondname,
        ].join(' '),
        paternalSurname: objValues?.pii?.lastname,
        maternalSurname: objValues?.pii?.motherlastname,
        birthdate: objValues?.pii?.birthdate,
        state: objValues?.pii?.birthstate,
        sex: objValues?.pii?.sex,
      });

      setPickedKeyValue(objValues, 'pii.curp', curp);
    };

    setValues(objValues);
  };

  const onPaste = function ({ target }) { };

  const onSubmit = async function (event) {
    event.preventDefault();

    const objTouched = structuredClone(touched);
    fields
      .filter((field) => field?.display?.hidden !== true)
      .forEach((field) => {
        setPickedKeyValue(objTouched, field?.name, true);
        setTouched(objTouched);
      });

    try {
      if (validationSchema) {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
      }

      await handleSubmit(values);
    } catch (error) {
      const objErrors = {};

      (error?.inner || []).forEach((item) => {
        setPickedKeyValue(objErrors, item.path, item.message);
      });

      setErrors(objErrors);
    }
  };

  return (<form id={id} onSubmit={onSubmit}>
    <Grid container spacing={2}>
      {title && (<Grid item {...BREAKPOINTS}>
        <Divider
          children={<Typography
            className={classes.divider}
            children={title}
          />}
        />
      </Grid>)}
      {fields
        .filter((field) => field?.display?.hidden !== true)
        .map((field, index) => {

          return (<Grid item key={index} {...(field?.display?.breakpoints || BREAKPOINTS)}>

            <label className={classes.labelContainer} htmlFor={field.name}>
              <Typography
                className={classes.labelText}
                children={field.label || <>&nbsp;</>}
              />
              {field?.display?.adornment?.label?.end?.icon && (
                <StyledTooltip title={field.display.adornment.label.end.tooltip || ''}>
                  <SvgIcon
                    children={field.display.adornment.label.end.icon}
                    className={classes.labelAdornment}
                  />
                </StyledTooltip>
              )}
            </label>

            {(function () {
              switch (field?.component?.type) {
                default: {
                  return (<StyledTextField
                    autoComplete={field?.component?.props?.autoComplete}
                    disabled={field.disabled}
                    error={!!pickValue(touched, field?.name) && !!pickValue(errors, field?.name)}
                    fullWidth
                    helperText={pickValue(errors, field?.name)}
                    id={`${id}_${field?.name}`}
                    name={field?.name}
                    onBlur={function (event) {
                      onBlur(event);

                      if (typeof field?.events?.onBlur === 'function') {
                        field.events.onBlur(event);
                      }
                    }}
                    onChange={function (event) {
                      onChange(event);

                      if (typeof field?.events?.onChange === 'function') {
                        field.events.onChange(event);
                      }
                    }}
                    onPaste={function (event) {
                      if (field?.component?.props?.canPaste === false) {
                        event.preventDefault();
                      }

                      onPaste(event);

                      if (typeof field?.events?.onPaste === 'function') {
                        field.events.onPaste(event);
                      }
                    }}
                    size="small"
                    value={pickValue(values, field.name)}
                    variant="outlined"
                  />);
                }

                case TYPES.DATEPICKER: {
                  const setEvent = (selected) => {
                    try {
                      const [value] = selected.toISOString().split('T');

                      return {
                        target: {
                          name: field?.name,
                          value,
                        },
                      };
                    } catch (error) {
                      return {
                        target: {
                          name: field?.name,
                          value: null,
                        },
                      };
                    }
                  };

                  return (<StyledKeyboardDatePicker
                    disabled={field.disabled}
                    error={!!pickValue(touched, field?.name) && !!pickValue(errors, field?.name)}
                    format='YYYY-MM-DD'
                    fullWidth
                    helperText={pickValue(errors, field?.name)}
                    id={`${id}_${field?.name}`}
                    inputVariant="outlined"
                    name={field?.name}
                    onChange={function (selected) {
                      const event = setEvent(selected);

                      onChange(event);

                      if (typeof field?.events?.onChange === 'function') {
                        field.events.onChange(event);
                      }
                    }}
                    onBlur={function (event) {
                      onBlur(event);

                      if (typeof field?.events?.onBlur === 'function') {
                        field.events.onBlur(event);
                      }
                    }}
                    size="small"
                    value={pickValue(values, field.name)}
                  />);
                };

                case TYPES.MASK: {
                  const inputComponent = {
                    currency: InputCurrency,
                    phone_number: InputMxPhone,
                  }[field?.component?.props?.format];

                  return (<StyledTextField
                    disabled={field.disabled}
                    error={!!pickValue(touched, field?.name) && !!pickValue(errors, field?.name)}
                    fullWidth
                    helperText={pickValue(errors, field?.name)}
                    id={`${id}_${field?.name}`}
                    InputProps={{
                      inputComponent,
                    }}
                    name={field?.name}
                    onChange={function (event) {
                      onChange(event);

                      if (typeof field?.events?.onChange === 'function') {
                        field.events.onChange(event);
                      }
                    }}
                    onBlur={function (event) {
                      onBlur(event);

                      if (typeof field?.events?.onBlur === 'function') {
                        field.events.onBlur(event);
                      }
                    }}
                    size="small"
                    value={pickValue(values, field.name)}
                    variant="outlined"
                  />);
                };

                case TYPES.SELECT: {
                  const options = field?.component?.props?.options || [];

                  return (<StyledTextField
                    children={options.map((option, idx) => (
                      <MenuItem
                        key={idx}
                        value={option.id}
                        children={option.name}
                      />
                    ))}
                    disabled={field.disabled}
                    error={!!pickValue(touched, field?.name) && !!pickValue(errors, field?.name)}
                    fullWidth
                    helperText={pickValue(errors, field?.name)}
                    id={`${id}_${field?.name}`}
                    name={field?.name}
                    onChange={function (event) {
                      onChange(event);

                      if (typeof field?.events?.onChange === 'function') {
                        field.events.onChange(event);
                      }
                    }}
                    select
                    size="small"
                    value={pickValue(values, field.name)}
                    variant="outlined"
                  />);
                }
              }
            })()}
          </Grid>);
        })}

    </Grid>
  </form>);
}

Form.defaultProps = {
  id: 'frmPeople',
  initialValues: {},
};

Form.propTypes = {
  classes: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.shape({
    component: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(TYPES)),
      props: PropTypes.shape({
        autoComplete: PropTypes.oneOf([
          'off',
          'on',
        ]),
        canPaste: PropTypes.bool,
        format: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          name: PropTypes.string,
        })),
      }),
    }),
    display: PropTypes.shape({
      breakpoints: PropTypes.shape({
        lg: PropTypes.number,
        md: PropTypes.number,
        sm: PropTypes.number,
        xl: PropTypes.number,
        xs: PropTypes.number,
      }),
      hidden: PropTypes.bool,
    }),
    events: PropTypes.shape({
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
    }),
    label: PropTypes.string,
    name: PropTypes.string,
  })),
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  validationSchema: PropTypes.object,
};

export default Form;
