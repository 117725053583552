import { lazy } from 'react';

import Layout from 'layouts/AuthenticationLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const basePath = '/auth';

const authenticationRoutes = {
  layout: Layout,
  path: basePath,
  routes: [
    {
      component: lazy(() => import('views/Authentication/SignIn')),
      exact: true,
      path: `${basePath}/login`,
    },
    {
      component: lazy(() => import('views/Authentication/Recovery')),
      exact: true,
      path: `${basePath}/recovery`,
    },
    error404,
  ],
};

export default authenticationRoutes;
