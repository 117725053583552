import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  check: {
    color: '#0B6BDD',
  },
  connector: {
    backgroundColor: 'white',
    height: '2.5rem',
    width: '3px',
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dotActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#FFDC5F',
  },
  dotFinished: {
    backgroundColor: '#FFFFFF',
  },
  dotPending: {
    backgroundColor: '#28742D',
  },
  stepDescription: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    width: '200px',
  },
  stepNumber: {
    fontWeight: 'bold',
    margin: '1px 3px',
    textAlign: 'center',
    width: '1.25rem',
  },
  stepTextInactive: {
    color: '#stepTextInactive',
  },
  stepTitle: {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    marginBottom: '5px',
    marginTop: '3px',
  },
}));
