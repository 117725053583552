import { node } from 'prop-types';

import TopBar from './TopBar';
import useStyles from './styles';

function DashboardLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />

      <div className={classes.wrapperCollapsed}>
        <div className={classes.contentContainer}>
          <div
            children={children}
            className={classes.content}
          />
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: node,
};

export default DashboardLayout;
