import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import Layout from 'layouts/HomeLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const basePath = '/home';

const requestRoutes = {
  guard: AuthGuard,
  layout: Layout,
  path: basePath,
  routes: [
    {
      component: lazy(() => import('views/Home/Root')),
      exact: true,
      path: `${basePath}`,
    },
    {
      component: lazy(() => import('views/Home/Search')),
      exact: true,
      path: `${basePath}/search`,
    },
    {
      component: lazy(() => import('views/Home/Confirm')),
      exact: true,
      path: `${basePath}/confirm/:clientId`,
    },
    error404,
  ],
};

export default requestRoutes;
