import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SplashScreen from './SplashScreen';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return (<SplashScreen />);
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
