import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import Layout from 'layouts/RequestLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const basePath = '/request';

const requestRoutes = {
  guard: AuthGuard,
  layout: Layout,
  path: basePath,
  routes: [
    {
      component: lazy(() => import('views/Request/Contract')),
      exact: true,
      path: `${basePath}/contract/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Device')),
      exact: true,
      path: `${basePath}/device/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Documents')),
      exact: true,
      path: `${basePath}/documents/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Finish')),
      exact: true,
      path: `${basePath}/finished/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/LoanIphone')),
      exact: true,
      path: `${basePath}/loan_iphone/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Identity')),
      exact: true,
      path: `${basePath}/:flowType/identity`,
    },
    {
      component: lazy(() => import('views/Request/Identity')),
      exact: true,
      path: `${basePath}/identity/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Info')),
      exact: true,
      path: `${basePath}/info/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Loan')),
      exact: true,
      path: `${basePath}/loan/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Products')),
      exact: true,
      path: `${basePath}/products/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Questions')),
      exact: true,
      path: `${basePath}/questions/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Relationships')),
      exact: true,
      path: `${basePath}/relationships/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Score')),
      exact: true,
      path: `${basePath}/score/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Software')),
      exact: true,
      path: `${basePath}/software/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Status')),
      exact: true,
      path: `${basePath}/status/:clientId`,
    },
    {
      component: lazy(() => import('views/Request/Verify')),
      exact: true,
      path: `${basePath}/verify/:clientId`,
    },
    error404,
  ],
};

export default requestRoutes;
