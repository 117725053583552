const BAD_WORDS = {
  BACA: 'BXCA',
  BAKA: 'BXKA',
  BUEI: 'BXEI',
  BUEY: 'BXEY',
  CACA: 'CXCA',
  CACO: 'CXCO',
  CAGA: 'CXGA',
  CAGO: 'CXGO',
  CAKA: 'CXKA',
  CAKO: 'CXKO',
  COGE: 'CXGE',
  COGI: 'CXGI',
  COJA: 'CXJA',
  COJE: 'CXJE',
  COJI: 'CXJI',
  COJO: 'CXJO',
  COLA: 'CXLA',
  CULO: 'CXLO',
  FALO: 'FXLO',
  FETO: 'FXTO',
  GETA: 'GXTA',
  GUEI: 'GXEI',
  GUEY: 'GXEY',
  JETA: 'JXTA',
  JOTO: 'JXTO',
  KACA: 'KXCA',
  KACO: 'KXCO',
  KAGA: 'KXGA',
  KAGO: 'KXGO',
  KAKA: 'KXKA',
  KAKO: 'KXKO',
  KOGE: 'KXGE',
  KOGI: 'KXGI',
  KOJA: 'KXJA',
  KOJE: 'KXJE',
  KOJI: 'KXJI',
  KOJO: 'KXJO',
  KOLA: 'KXLA',
  KULO: 'KXLO',
  LILO: 'LXLO',
  LOCA: 'LXCA',
  LOCO: 'LXCO',
  LOKA: 'LXKA',
  LOKO: 'LXKO',
  MAME: 'MXME',
  MAMO: 'MXMO',
  MEAR: 'MXAR',
  MEAS: 'MXAS',
  MEON: 'MXON',
  MIAR: 'MXAR',
  MION: 'MXON',
  MOCO: 'MXCO',
  MOKO: 'MXKO',
  MULA: 'MXLA',
  MULO: 'MXLO',
  NACA: 'NXCA',
  NACO: 'NXCO',
  PEDA: 'PXDA',
  PEDO: 'PXDO',
  PENE: 'PXNE',
  PIPI: 'PXPI',
  PITO: 'PXTO',
  POPO: 'PXPO',
  PUTA: 'PXTA',
  PUTO: 'PXTO',
  QULO: 'QXLO',
  RATA: 'RXTA',
  ROBA: 'RXBA',
  ROBE: 'RXBE',
  ROBO: 'RXBO',
  RUIN: 'RXIN',
  SENO: 'SXNO',
  TETA: 'TXTA',
  VACA: 'VXCA',
  VAGA: 'VXGA',
  VAGO: 'VXGO',
  VAKA: 'VXKA',
  VUEI: 'VXEI',
  VUEY: 'VXEY',
  WUEI: 'WXEI',
  WUEY: 'WXEY',
};

const COMMON_NAMES = [
  'MARIA DEL ',
  'MARIA DE LOS ',
  'MARIA ',
  'JOSE DE ',
  'JOSE ',
  'MA. ',
  'MA ',
  'M. ',
  'J. ',
  'J ',
];

const SEX = {
  MALE: 'H',
  FEMALE: 'M',
};

const STATE = {
  AGS: 'AS',
  BC: 'BC',
  BCS: 'BS',
  CAMP: 'CC',
  CDMX: 'DF',
  CHIH: 'CH',
  CHIS: 'CS',
  COAH: 'CL',
  COL: 'CM',
  DGO: 'DG',
  GRO: 'GR',
  GTO: 'GT',
  HGO: 'HG',
  JAL: 'JC',
  MEX: 'MC',
  MICH: 'MN',
  MOR: 'MS',
  NAY: 'NT',
  NL: 'NL',
  OAX: 'OC',
  PUE: 'PL',
  QRO: 'QT',
  QROO: 'QR',
  SIN: 'SL',
  SLP: 'SP',
  SON: 'SR',
  TAB: 'TC',
  TAMP: 'TS',
  TLAX: 'TL',
  VER: 'VZ',
  YUC: 'YN',
  ZAC: 'ZS',
};

function zeropad(ancho, num) {
  const pad = Array.apply(0, Array.call(0, ancho))
    .map(() => 0)
    .join('');

  return (pad + num).replace(new RegExp(`^.*([0-9]{${ancho}})$`), '$1');
}

function adjustCompound(str) {
  [
    /\bDA\b/,
    /\bDAS\b/,
    /\bDE\b/,
    /\bDEL\b/,
    /\bDER\b/,
    /\bDI\b/,
    /\bDIE\b/,
    /\bDD\b/,
    /\bEL\b/,
    /\bLA\b/,
    /\bLOS\b/,
    /\bLAS\b/,
    /\bLE\b/,
    /\bLES\b/,
    /\bMAC\b/,
    /\bMC\b/,
    /\bVAN\b/,
    /\bVON\b/,
    /\bY\b/,
  ].forEach((item) => {
    if (item.test(str)) {
      str = str.replace(item, '');
    }
  });

  return str.trim();
}

function checksum(str) {
  const dictionary = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
  let lnSum = 0.0;
  let lnDigt = 0.0;
  for (let i = 0; i < 17; i++) {
    lnSum += dictionary.indexOf(str.charAt(i)) * (18 - i);
  }

  lnDigt = 10 - (lnSum % 10);

  return (lnDigt === 10) ? 0 : lnDigt;
}

function cleanWords(str) {
  const output = str.replace(/[\d_\-./\\,]/g, 'X');

  return BAD_WORDS[output]
    ? BAD_WORDS[output]
    : output;
}

function firstConsonant(str) {
  const output = str
    .substring(1)
    .replace(/[AEIOU]/gi, '')
    .substring(0, 1);

  return output === '' || output === 'Ñ' ? 'X' : output;
}

function getNameToUse(name) {
  const names = name.trim().split(/\s+/);
  if (names.length === 1) {
    return names[0];
  };

  return COMMON_NAMES.some((item) => name.indexOf(item) === 0)
    ? names[1]
    : names[0];
}

function normalizeString(str) {
  const from = [
    'Ã',
    'À',
    'Á',
    'Ä',
    'Â',
    'È',
    'É',
    'Ë',
    'Ê',
    'Ì',
    'Í',
    'Ï',
    'Î',
    'Ò',
    'Ó',
    'Ö',
    'Ô',
    'Ù',
    'Ú',
    'Ü',
    'Û',
    'ã',
    'à',
    'á',
    'ä',
    'â',
    'è',
    'é',
    'ë',
    'ê',
    'ì',
    'í',
    'ï',
    'î',
    'ò',
    'ó',
    'ö',
    'ô',
    'ù',
    'ú',
    'ü',
    'û',
    'Ç',
    'ç',
  ];
  const to = [
    'A',
    'A',
    'A',
    'A',
    'A',
    'E',
    'E',
    'E',
    'E',
    'I',
    'I',
    'I',
    'I',
    'O',
    'O',
    'O',
    'O',
    'U',
    'U',
    'U',
    'U',
    'a',
    'a',
    'a',
    'a',
    'a',
    'e',
    'e',
    'e',
    'e',
    'i',
    'i',
    'i',
    'i',
    'o',
    'o',
    'o',
    'o',
    'u',
    'u',
    'u',
    'u',
    'c',
    'c',
  ];
  const input = str.toUpperCase().split('');
  const output = input.map(function (char) {
    const position = from.indexOf(char);
    return position > -1 ? to[position] : char;
  });

  return output.join('');
}

export function generateCurp(people) {
  const name = getNameToUse(adjustCompound(normalizeString(people?.name || 'X')));
  const paternalSurname = adjustCompound(normalizeString(people?.paternalSurname || 'X'));
  const maternalSurname = adjustCompound(normalizeString(people?.maternalSurname || 'X'));

  let vowelPaternalSurname = paternalSurname
    .substring(1)
    .replace(/[BCDFGHJKLMNÑPQRSTVWXYZ]/g, '')
    .substring(0, 1);
  vowelPaternalSurname = vowelPaternalSurname === '' ? 'X' : vowelPaternalSurname;

  const firstBlock = cleanWords([
    paternalSurname.substring(0, 1).replace('Ñ', 'X'),
    vowelPaternalSurname,
    maternalSurname.substring(0, 1).replace('Ñ', 'X'),
    name.substring(0, 1),
  ].join(''));

  const birthdate = (people?.birthdate || '').split('-');
  const pad = zeropad.bind(null, 2);
  const secondBlock = [
    pad(birthdate[0] - 1900),
    pad(birthdate[1] || 0),
    pad(birthdate[2] || 0),
  ].join('');

  const thirdBlock = [
    SEX[(people?.sex || '').toUpperCase()] || 'X',
    STATE[(people?.state || '').toUpperCase()] || 'NE',
  ].join('');

  const fourthBlock = [
    firstConsonant(paternalSurname),
    firstConsonant(maternalSurname),
    firstConsonant(name),
    birthdate[0][0] === '1' ? '0' : 'A',
  ].join('');

  const curp = [
    firstBlock,
    secondBlock,
    thirdBlock,
    fourthBlock,
  ].join('');

  return curp + checksum(curp);
}
