import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import useStyles from './style';

function CardSelector(props) {
  const classes = useStyles();
  const {
    error,
    events,
    name,
    onChange,
    settings = {},
    value,
  } = props;

  const { optionsPerRow = 2 } = settings;

  const options = typeof settings.options === 'function'
    ? settings.options()
    : settings.options || [];

  const handleChange = (value) => {
    const target = {
      name,
      value,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }
  };

  return (<>

    {error && (<Alert
      children={error}
      severity="error"
    />)}

    <Grid container spacing={2}>
      {options.map((option, index) => (
        <Box
          display="inline-block"
          key={index}
          padding={1}
          width={1 / optionsPerRow}
        >
          <Card
            onClick={() => handleChange(options[index].id)}
            className={clsx(
              (value === option.id ? classes.cardSelected : classes.card),
              classes[option.color],
            )}
          >
            <CardContent align='center'>
              <Typography
                children={option.description || <br />}
                variant="subtitle1"
              />
              <Typography
                children={option.name}
                variant="body1"
              />
              <Typography
                children={option.subtitle || <br />}
                color={'#2464F0'}
                variant="subtitle2"
              />
            </CardContent>
          </Card>
        </Box>
      ))}
    </Grid>
  </>);
}

CardSelector.defaultProps = {
  onChange: () => { },
};

CardSelector.propTypes = {
  error: PropTypes.string,
  events: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  settings: PropTypes.shape({
    optionsPerRow: PropTypes.number,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
  }),
  value: PropTypes.string,
};

export default CardSelector;
