import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import Layout from 'layouts/PaymentsLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const basePath = '/payments';

const paymentsRoutes = {
  guard: AuthGuard,
  layout: Layout,
  path: basePath,
  routes: [
    {
      component: lazy(() => import('views/Payments/Find')),
      exact: true,
      path: `${basePath}/find`,
    },
    {
      component: lazy(() => import('views/Payments/Finished')),
      exact: true,
      path: `${basePath}/finished/:clientId/:accountId`,
    },
    {
      component: lazy(() => import('views/Payments/Process')),
      exact: true,
      path: `${basePath}/process/:clientId`,
    },
    error404,
  ],
};

export default paymentsRoutes;
